import { useState } from "react";
import { useSelector } from "react-redux";

const ItemListInModal = ({
    itemsSelector,
    ItemCard,
    onItemClick = () => {},
    isSecondStepRequired = false,
    onActionClick = () => {},
    userId,
    action,
}) => {
    const items = useSelector(itemsSelector);
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [isCommentFieldVisible, setIsCommentFieldVisible] = useState(false);
    const [comment, setComment] = useState("");

    const handleItemSelect = (item) => {
        setSelectedItemId(item.id);
        onItemClick(item);
        if (isSecondStepRequired) {
            setIsCommentFieldVisible(true);
        }
    };

    const handleActionClick = () => {
        console.log("Action clicked", action);

        onActionClick({
            action,
            id: selectedItemId,
            commentForUser: comment,
            userId,
        });
    };

    return (
        <div className="d-flex flex-wrap">
            {items?.length > 0 &&
                !isCommentFieldVisible &&
                items.map((item) => (
                    <ItemCard
                        key={item.id}
                        item={item}
                        onItemClick={handleItemSelect}
                        selectedItemId={selectedItemId}
                    />
                ))}
            {isSecondStepRequired && isCommentFieldVisible && (
                <div className="d-flex flex-column gap-2">
                    <textarea
                        className="form-control"
                        placeholder="Введите комментарий"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                    />
                    <button
                        className="btn btn-primary"
                        disabled={!comment.length}
                        onClick={handleActionClick}
                    >
                        Подтвердить
                    </button>
                </div>
            )}
        </div>
    );
};

export default ItemListInModal;
