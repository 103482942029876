import React from "react";
import UserLogo from "./Stena/UserLogo";
import DropdownItem from "./BurgerMenu/DropdownItem";
import AccordionItem from "./BurgerMenu/AccordionItem";
import ActionButton from "./BurgerMenu/ActionButton";
import { useSelector } from "react-redux";
import { links } from "./BurgerMenu/links";

const renderLinks = (category) =>
    links[category].map((item) => <DropdownItem key={item.id} item={item} />);

function BurgerMenu() {
    const meowcoins = document.getElementById("maucony-count").innerHTML;
    const rediks = document.getElementById("rediki-count").innerHTML;

    const currentUser = useSelector((state) => state.users?.currentUser);

    return (
        <>
            <button
                className="btn d-block d-lg-none rounded-start bg-white border-0"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#staticBackdrop"
                aria-controls="staticBackdrop"
            >
                <img className="" src="/img/sidebar-menu.svg" alt="Меню" />
            </button>

            <div
                className="offcanvas offcanvas-start overflow-auto"
                tabIndex="-1"
                id="staticBackdrop"
                aria-labelledby="staticBackdropLabel"
                style={{ width: "300px" }}
            >
                <button
                    type="button"
                    className="btn-close justify-content-end position-absolute top-0 end-0 mt-3 me-3"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                ></button>

                <div className="d-flex flex-column">
                    <div className="container-fluid gap-2">
                        <div className="ps-2 pt-2 pe-2">
                            <AccordionItem
                                id="profileCollapse"
                                title="Профиль"
                                icons={
                                    currentUser?.id && (
                                        <UserLogo
                                            user={currentUser}
                                            size="extra-small"
                                        />
                                    )
                                }
                            >
                                {renderLinks("profile")}
                            </AccordionItem>
                            <div className="ps-2 pt-2 pe-2">
                                <div className="d-flex gap-2 align-items-center">
                                    <div className="btn border-0 d-flex gap-2 px-0">
                                        <img
                                            className="w-20"
                                            src="/img/bell-28px.svg"
                                        />
                                        <a
                                            href="/bell"
                                            className="link-underline d-md-none link-underline-opacity-0 link-secondary link-underline-opacity-100-hover"
                                        >
                                            Уведомления
                                        </a>
                                    </div>
                                    <div
                                        className="rounded-circle bg-primary ps-0"
                                        style={{ width: "8px", height: "8px" }}
                                    ></div>
                                </div>

                                <div className="btn border-0 d-flex gap-2 ps-0">
                                    <img
                                        className="w-20"
                                        src="/img/group-light.svg"
                                    />
                                    <a
                                        href="/users"
                                        className="link-underline d-md-none link-underline-opacity-0 link-secondary link-underline-opacity-100-hover"
                                    >
                                        Коллеги
                                    </a>
                                </div>
                                <div className="btn border-0 d-flex gap-2 ps-0">
                                    <img
                                        className="w-20"
                                        src="/img/meowmarket-light.svg"
                                    />
                                    <a
                                        href="/shop"
                                        className="link-underline  d-md-none link-underline-opacity-0 link-secondary link-underline-opacity-100-hover"
                                    >
                                        МяуМаркет
                                    </a>
                                </div>
                            </div>
                            <AccordionItem
                                id="megaphoneCollapse"
                                title="Будь в курсе"
                                icon="/img/megaphone.svg"
                            >
                                {renderLinks("notifications")}
                            </AccordionItem>

                            <AccordionItem
                                id="graduationCollapse"
                                title="Обучение и развитие"
                                icon="/img/graduation-cap.svg"
                            >
                                {renderLinks("education")}
                            </AccordionItem>

                            <AccordionItem
                                id="profileCircleCollapse"
                                title="Сервисы сотрудника"
                                icon="/img/profile-circle.svg"
                            >
                                {renderLinks("employeeServices")}
                            </AccordionItem>
                        </div>
                    </div>
                </div>

                <div className="container-fluid gap-2">
                    <div className="row align-items-center">
                        <div className="d-flex flex-column gap-2 ms-2">
                            <div className="d-flex gap-2 align-items-center">
                                <div className="d-flex align-items-center col-3 gap-1">
                                    <img
                                        style={{
                                            width: "18px",
                                            height: "18px",
                                        }}
                                        src="/img/meow.svg"
                                        alt="Мяу-коины"
                                    />
                                    {meowcoins}
                                </div>
                                <ActionButton
                                    icon="/img/simple-cart.svg"
                                    label="Потратить"
                                    link="/shop"
                                />
                            </div>

                            <div className="d-flex gap-2 align-items-center">
                                <div className="d-flex align-items-center col-3 gap-1">
                                    <img
                                        style={{
                                            width: "18px",
                                            height: "18px",
                                        }}
                                        src="/img/redik.svg"
                                        alt="Редики"
                                    />
                                    {rediks}
                                </div>
                                {/* <ActionButton
                                    icon="/img/dice-six.svg"
                                    label="Рандомное спасибо"
                                    link="/users/randomusers"
                                /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BurgerMenu;
