import { useDispatch, useSelector } from "react-redux";
import ShopList from "./PostField/ShopList";
import { useEffect, useState } from "react";
import { fetchExtraTypes } from "../redux/extraTypesSlice";
import { fetchCurrentUserId, fetchUsers } from "../redux/usersSlice";
import SearchSVG from "./Icons/SearchSVG";
import SecondStage from "./PostField/ShopModal/SecondStage";
import ThirdStage from "./PostField/ShopModal/ThirdStage";
import axios from "axios";

const ShopWrapper = ({ activeCategory = 0 }) => {
    const { Shop } = useSelector((state) => state.extraTypes.extraTypes);
    const {
        extraCommentsForAdmin: commentForAdmin,
        extraCommentsForUser: commentForUser,
    } = useSelector((state) => state.extraTypes);
    // console.log("Shop", Shop);

    const { currentUser } = useSelector((state) => state.users);

    const dispatch = useDispatch();

    const [inputValue, setInputValue] = useState("");
    const [showAccessibleOnly, setShowAccessibleOnly] = useState(false);
    const [sorting, setSorting] = useState({ title: "", id: null });
    const [filtredItems, setFiltredItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [thirdStageActive, setThirdStageActive] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);

    function resetStateToDefault() {
        setSelectedItem(null);
        setThirdStageActive(false);
        setSelectedUser(currentUser);
        setInputValue("");
        // setShowAccessibleOnly(false);
        // setSorting({ title: "", id: null });
    }

    useEffect(() => {
        if (currentUser.id) {
            setSelectedUser(currentUser);
        }
    }, [currentUser]);

    // console.log("activeCategory", activeCategory);

    async function fetchData() {
        await dispatch(fetchExtraTypes(true));
        await dispatch(fetchUsers(true));
        await dispatch(fetchCurrentUserId(true));
    }

    useEffect(() => {
        if (!Shop) {
            fetchData();
        } else {
            handleFilterAndSort();
        }
    }, [Shop, inputValue, showAccessibleOnly, sorting, activeCategory]);

    const handleFilterAndSort = (selectedCategory = null) => {
        let filtered;
        if (!selectedCategory || selectedCategory.id === 0) {
            filtered = Shop.filter((item) =>
                activeCategory * 1 ? item.cat * 1 === activeCategory * 1 : item
            );
        } else {
            // console.log("selectedCategory", selectedCategory);

            filtered = Shop.filter(
                (item) => item.cat * 1 === selectedCategory.id * 1
            );
        }

        if (inputValue) {
            filtered = filtered.filter((item) =>
                item.name.toLowerCase().includes(inputValue.toLowerCase())
            );
        }

        if (showAccessibleOnly) {
            filtered = filtered.filter((item) => item.summ <= currentUser.meow);
        }

        if (sorting && sorting.id) {
            filtered = filtered.sort((a, b) => {
                if (sorting.id === "asc") {
                    return sorting.type === "name"
                        ? a.name.localeCompare(b.name)
                        : a.summ - b.summ;
                } else {
                    return sorting.type === "name"
                        ? b.name.localeCompare(a.name)
                        : b.summ - a.summ;
                }
            });
        }

        setFiltredItems(filtered);
    };

    function handleItemSelect(item) {
        console.log("handleItemSelect", item);

        setSelectedItem(item);
    }

    async function handleConfirm(item) {
        setSelectedItem(item);
        try {
            await sendPurchase(item);
            setThirdStageActive(true);
        } catch (error) {
            console.log(error);
            alert("Не удалось купить товар");
            throw error;
        }
    }

    async function sendPurchase(item) {
        console.log("sendPurchase", item);
        try {
            console.log("userId", selectedUser.id);
            const res = await axios.post("/shop/purchases", {
                userId: selectedUser.id, //еще и для другого надо
                itemId: item.id,
                quantity: item.quantity,
                commentForAdmin,
                commentForUser,
            });
            console.log("res", res);
            return res;
        } catch (error) {
            // console.log(error);
            // alert("Не удалось купить товар");
            throw error;
        }
    }

    return (
        <div>
            {/* Десктопная версия */}
            <div className="d-none d-md-block">
                <div className="d-flex flex-column gap-4">
                    <div className="d-flex gap-3 align-items-center">
                        {/* Поле поиска */}
                        <div className="input-group align-items-center">
                            <span className="z-999">
                                <button
                                    className="btn border-0 me-n5"
                                    type="button"
                                >
                                    <SearchSVG />
                                </button>
                            </span>
                            <input
                                type="text"
                                className="form-control ps-5 rounded bg-gray-2 border-gray-2"
                                placeholder="Поиск по МяуМаркету"
                                aria-label="Поиск по МяуМаркету"
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                            />
                        </div>

                        {/* Чекбокс "Доступные мне" */}
                        <div className="form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="accessibleOnly"
                                checked={showAccessibleOnly}
                                onChange={() =>
                                    setShowAccessibleOnly(!showAccessibleOnly)
                                }
                            />
                            <label
                                className="form-check-label"
                                htmlFor="accessibleOnly"
                            >
                                Доступные мне
                            </label>
                        </div>

                        {/* Сортировка */}
                        <div className="dropdown">
                            <button
                                className="btn text-muted text-nowrap p-0 border-0"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                Сортировка:{" "}
                                <span className="text-lowercase">
                                    {sorting.title || "Без сортировки"}
                                </span>
                            </button>
                            <div className="dropdown-menu pe-5">
                                {[
                                    {
                                        id: "asc",
                                        title: "По названию (возрастание)",
                                        type: "name",
                                    },
                                    {
                                        id: "desc",
                                        title: "По названию (убывание)",
                                        type: "name",
                                    },
                                    {
                                        id: "asc",
                                        title: "По цене (возрастание)",
                                        type: "price",
                                    },
                                    {
                                        id: "desc",
                                        title: "По цене (убывание)",
                                        type: "price",
                                    },
                                ].map((item) => (
                                    <li key={item.id + item.type}>
                                        <a
                                            className="dropdown-item"
                                            href="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setSorting(item);
                                            }}
                                        >
                                            {item.title}
                                        </a>
                                    </li>
                                ))}
                            </div>
                        </div>
                    </div>

                    {/* Контент */}
                    {thirdStageActive && selectedItem ? (
                        <ThirdStage
                            item={selectedItem}
                            onClose={resetStateToDefault}
                            selectedUser={currentUser}
                        />
                    ) : !selectedItem ? (
                        <ShopList
                            shopList={filtredItems}
                            handleItemSelect={handleItemSelect}
                        />
                    ) : (
                        <SecondStage
                            item={selectedItem}
                            selectedUser={selectedUser}
                            setSelectedUser={setSelectedUser}
                            onReturnBtnClick={() => setSelectedItem(null)}
                            handleConfirm={handleConfirm}
                            userCanBeChanged={true}
                            isCommentForUserRequired={
                                selectedUser?.id !== currentUser?.id
                            }
                        />
                    )}
                </div>
            </div>

            {/* Мобильная версия */}
            <div className="d-md-none">
                <div className="d-flex flex-column gap-3">
                    {/* Кнопка для показа фильтров */}
                    <button
                        className="btn bg-gray-2 sk-hover-gray-3 d-flex align-items-center justify-content-between"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#mobileFilters"
                        aria-expanded="false"
                        aria-controls="mobileFilters"
                    >
                        Фильтры и сортировка <span>🔻</span>
                    </button>

                    {/* Фильтры и сортировка в мобильной версии */}
                    <div id="mobileFilters" className="collapse">
                        <div className="d-flex flex-column gap-3">
                            {/* Поле поиска */}
                            <div className="input-group">
                                <span className="z-999">
                                    <button
                                        className="btn border-0 me-n5"
                                        type="button"
                                    >
                                        <SearchSVG />
                                    </button>
                                </span>
                                <input
                                    type="text"
                                    className="form-control ps-5 rounded bg-gray-2 border-gray-2"
                                    placeholder="Поиск по МяуМаркету"
                                    aria-label="Поиск по МяуМаркету"
                                    value={inputValue}
                                    onChange={(e) =>
                                        setInputValue(e.target.value)
                                    }
                                />
                            </div>

                            {/* Чекбокс "Доступные мне" */}
                            <div className="form-check">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="accessibleOnlyMobile"
                                    checked={showAccessibleOnly}
                                    onChange={() =>
                                        setShowAccessibleOnly(
                                            !showAccessibleOnly
                                        )
                                    }
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="accessibleOnlyMobile"
                                >
                                    Доступные мне
                                </label>
                            </div>

                            {/* Категории (новый блок) */}
                            <div className="dropdown">
                                <button
                                    className="btn text-muted text-nowrap p-0 border-0"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    Категория:{" "}
                                    <span className="text-lowercase">
                                        {selectedCategory?.title ||
                                            "Все категории"}
                                    </span>
                                </button>
                                <div className="dropdown-menu pe-5">
                                    {[
                                        { id: 0, title: "Все товары" },
                                        { id: 7, title: "Бесценное" },
                                        { id: 5, title: "Вкусняшки" },
                                        { id: 6, title: "Развлечения" },
                                        { id: 1, title: "Сертификаты" },
                                        { id: 3, title: "Мелочи для работы" },
                                        { id: 4, title: "Благотворительность" },
                                        {
                                            id: 2,
                                            title: "Брендированные товары",
                                        },
                                    ].map((category) => (
                                        <li key={category.id}>
                                            <a
                                                className="dropdown-item"
                                                href="#"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setSelectedCategory(
                                                        category
                                                    );
                                                    handleFilterAndSort(
                                                        category
                                                    );
                                                }}
                                            >
                                                {category.title}
                                            </a>
                                        </li>
                                    ))}
                                </div>
                            </div>

                            {/* Сортировка */}
                            <div className="dropdown">
                                <button
                                    className="btn text-muted text-nowrap p-0 border-0"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    Сортировка:{" "}
                                    <span className="text-lowercase">
                                        {sorting.title || "Без сортировки"}
                                    </span>
                                </button>
                                <div className="dropdown-menu pe-5">
                                    {[
                                        {
                                            id: "asc",
                                            title: "По названию (возрастание)",
                                            type: "name",
                                        },
                                        {
                                            id: "desc",
                                            title: "По названию (убывание)",
                                            type: "name",
                                        },
                                        {
                                            id: "asc",
                                            title: "По цене (возрастание)",
                                            type: "price",
                                        },
                                        {
                                            id: "desc",
                                            title: "По цене (убывание)",
                                            type: "price",
                                        },
                                    ].map((item) => (
                                        <li key={item.id + item.type}>
                                            <a
                                                className="dropdown-item"
                                                href="#"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setSorting(item);
                                                }}
                                            >
                                                {item.title}
                                            </a>
                                        </li>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Контент */}
                    {thirdStageActive && selectedItem ? (
                        <ThirdStage
                            item={selectedItem}
                            onClose={resetStateToDefault}
                            selectedUser={currentUser}
                        />
                    ) : !selectedItem ? (
                        <ShopList
                            shopList={filtredItems}
                            handleItemSelect={handleItemSelect}
                        />
                    ) : (
                        <SecondStage
                            item={selectedItem}
                            selectedUser={selectedUser}
                            setSelectedUser={setSelectedUser}
                            onReturnBtnClick={() => setSelectedItem(null)}
                            handleConfirm={handleConfirm}
                            userCanBeChanged={true}
                            isCommentForUserRequired={
                                selectedUser?.id !== currentUser?.id
                            }
                            isMobile={true}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default ShopWrapper;
